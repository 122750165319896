import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import { BannerRatingWrapper } from "./style";

const GlobalRating = ({pageHeader}) => {
    return (
        <BannerRatingWrapper>
               <div className="ratingWrap">
                <StaticImage
                    src='../../data/images/global-rating/tag3.png'
                    alt='Users Love Us'
                />
            </div>
            <div className="ratingWrap">
                <StaticImage
                    src='../../data/images/global-rating/tag.png'
                    alt='Users Love Us'
                />
            </div>
            <div className="ratingWrap">
                <StaticImage
                    src='../../data/images/global-rating/tag2.png'
                    alt='G2'
                />
            </div>
            <div className="ratingWrap">
                <div className="ratingPoints">
                    <StaticImage
                        src='../../data/images/global-rating/star.png'
                        alt='G2'
                    />
                    <span className="ratingText points">(4.6)</span>
                </div>

                <>
                    {pageHeader === 'elearning-page-header' && (
                        <p className="ratingText  mt-1">#1 Best eLearning Localization Providers</p>
                    )}

                    {pageHeader === 'document-localization-page-header' && (
                        <p className="ratingText  mt-1">#1 Best Document Translation Services</p>
                    )}
                </>
            </div>

        </BannerRatingWrapper>
    );
};

export default GlobalRating;