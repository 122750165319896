import styled, { themeGet, device } from "@styled";
import whitewaveBg from "@data/images/bg/wave-bg.svg";

export const PageHeaderWrap = styled.section`
  padding-top: 102px;
  padding-bottom: 105px;
  position: relative;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  ${device.large} {
    height: auto;
    padding-top: 122px;
  }
  ${device.xlarge} {
    padding-top: 132px;
    min-height: 700px;
  }
  ${device.xxlarge} {
    padding-top: 132px;
    min-height: 800px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 103px;
    width: 100%;
    background: url(${whitewaveBg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: 9;
    ${device.xxlarge} {
      background-position: left bottom;
      background-size: cover;
    }
  }
  .container {
    ${device.xxlarge} {
      max-width: 1570px;
    }
  }
`;
export const PageHeaderContent = styled.div`
  margin-bottom: 20px;
  ${device.xlarge} {
    margin-bottom: 50px;
  }
  a {
    :not(:last-child) {
      margin-left: 0;
    }
  }
`;

export const StyledBG = styled.div`
  inset: 0;
  z-index: -1;
  margin-top: 60px;
  ${device.large} {
    margin-top: 0;
  }
  & > div {
    height: 100%;
    width: 100%;
  }
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }
`;
export const TagLine = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  line-height: 1;
  display: none;
  span {
    transform: skew(-15deg, 0deg);
    display: inline-block;
    color: ${themeGet("colors.primary")};
  }
  ${device.large} {
    display: block;
  }
`;

export const StyledTitle = styled.h1`
  color: #fff;
  line-height: 1.2;
  font-size: 33px;
  margin-bottom: 20px;
  padding-left: 10px;
  border-left: 5px solid ${themeGet("colors.primary")};
  ${device.small} {
    font-size: 35px;
  }
  ${device.medium} {
    line-height: 1.2;
    padding-left: 20px;
  }
  ${device.large} {
    font-size: 30px;
    line-height: 1.3;
  }
  ${device.xlarge} {
    font-size: 38px;
    margin-bottom: 30px;
  }
  ${device.xxlarge} {
    font-size: 50px;
    margin-bottom: 40px;
  }
`;

export const StyledDesc = styled.h5`
  font-size: 16px;
  font-weight: 400;
  color: #dbb5f6;
  margin-bottom: 30px;
  ${device.xlarge} {
    font-size: 18px;
  }
  ${device.xxlarge} {
    font-size: 20px;
  }
`;
export const StyledSubtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 25px;
`;
export const PageHeaderImgWrap = styled.div`
  position: relative;
`;
export const ScrollTosection = styled.div`
  position: relative;
`;
